import React, { useEffect, useState, useContext } from "react"
import { scrollToElement } from "../../../utility/utils"

import AgreementPolicy from "./components/agreementPolicy";
import PersonalInformation from "../personalInformation";
import PersonalInfoForm from "../personalInformation/personal-info-form";
import BVNVerification from '../bvn'
import Summary from './components/summary';
import SummaryDetails from "./components/summary/summary-details";

// import { OverdraftWrap } from './style';
import { StateContext } from "../../../../contextApi";
import moment from "moment";
import EligibileStatus from "../eligibileStatus";
import {
  DATE_OF_BIRTH, FIRST_NAME, LAST_NAME,
  LOOKALIKE_AUDIENCE, MEDIUM, CHANNEL, UTM_SOURCE,
  EMAIL, REASON
} from "../../../../util/eventLogger/segment/constants/eventProperties"
import * as eventLogger from "../../../../util/eventLogger";
import {
  PREQUALIFICATION_BVN_ENTERED,
  PREQUALIFICATION_BVN_VERIFICATION_FAILED,
  PREQUALIFICATION_ELIGIBILITY_CONFIRMED,
  PREQUALIFICATION_ELIGIBILITY_FAILED,
  PREQUALIFICATION_INFO_ENTERED
} from "../../../../util/eventLogger/segment/constants/eventTags";
import { validateEmail } from "../../../../util/util";
import { OverdraftWrap } from "./style";
const SeoTitles = [
  "Kuda | Overdraft Eligibility | Terms & Conditions",
  "Kuda | Overdraft Eligibility | Personal Information",
  "Kuda | Overdraft Eligibility | BVN Information",
  "Kuda | Overdraft Eligibility | Overdraft Offer Summary",
  "Kuda | Overdraft Eligibility | You might be eligible ",
  "Kuda | Overdraft Eligibility | We need more information"
]
const OverDraftCalculator = (props) => {
  const [{ }, dispatch] = useContext(StateContext)
  const [activePage, setActivePage] = useState(0);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [personalInfoFields, setPersonalInfoFields] = useState({
    dateOfBirth: null,
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    showErrMsg: false
  });

  const [bvnInputs, setBvnInputs] = useState({ bvn_no: "", })
  const [status, setStatus] = useState('');
  const [validationErrMsg, setValidationErrMsg] = useState("");

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  useEffect(() => {
    props.handleSeoTitle(SeoTitles[activePage])
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activePage])

  const congratsStatus = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const lookalike_audience = queryParameters.get("lookalike_audience");

    if (lookalike_audience && lookalike_audience !== "") {
      setStatus('Congrat');
      setActivePage((prev) => prev + 1);
      /**-----------------SEGMENT---------------------------- */
      eventLogger.pushEventGlobally(PREQUALIFICATION_ELIGIBILITY_CONFIRMED);
      /**---------------------------------------------------------------- */

    } else {
      setStatus('No-congrat')
      setActivePage((prev) => prev + 1);

      /**-----------------SEGMENT---------------------------- */
      const segmentData = {
        [REASON]: `user doesn't belong to a lookalike audience pot`
      }
      eventLogger.pushEventGlobally(PREQUALIFICATION_ELIGIBILITY_FAILED, segmentData);
      /**---------------------------------------------------------------- */
    }
  }

  const personalInfoStage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    /**-----------------SEGMENT---------------------------- */
    const segmentData = {
      [FIRST_NAME]: personalInfoFields.firstName,
      [LAST_NAME]: personalInfoFields.lastName,
      [DATE_OF_BIRTH]: moment(personalInfoFields.dateOfBirth).format('DD-MM-YYYY'),
      [EMAIL]: personalInfoFields.email,
      [LOOKALIKE_AUDIENCE]: queryParams.get("lookalike_audience"),
      [UTM_SOURCE]: queryParams.get('utm_source'),
      [MEDIUM]: queryParams.get('utm_medium'),
      [CHANNEL]: queryParams.get('utm_campaign')
    };

    const userData = {
      [FIRST_NAME]: personalInfoFields.firstName,
      [LAST_NAME]: personalInfoFields.lastName,
      [DATE_OF_BIRTH]: moment(personalInfoFields.dateOfBirth).format('DD-MM-YYYY'),
      [EMAIL]: personalInfoFields.email,
      [LOOKALIKE_AUDIENCE]: queryParams.get("lookalike_audience"),
      [UTM_SOURCE]: queryParams.get('utm_source'),
      [MEDIUM]: queryParams.get('utm_medium'),
      [CHANNEL]: queryParams.get('utm_campaign')
    };

    eventLogger.pushEventGlobally(PREQUALIFICATION_INFO_ENTERED, segmentData);
    eventLogger.identifyUser("", userData);
    /**---------------------------------------------------------------- */

    setActivePage((prev) => prev + 1);
  }

  const handleNextClick = () => {

    switch (activePage) {

      case 0:
        setActivePage((prev) => prev + 1);
        break;

      case 1:
        personalInfoStage();
        break;

      case 2:
        setActivePage((prev) => prev + 1);
        break;

      case 3:
        congratsStatus();
        break;

      default:
        setActivePage((prev) => prev + 1);
    }

  }

  const handleGoBack = () => {
    setActivePage((prev) => prev - 1);
  }

  const handleUserInput = (event) => {

    const { value, name } = event.target;

    setPersonalInfoFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const handleDateChange = (date) => {
    setPersonalInfoFields((prevState) => ({
      ...prevState,
      dateOfBirth: date,
    }));
  }

  function handleEmailValidation(email) {
    const error = validateEmail(email);

    setPersonalInfoFields((prevState) => ({
      ...prevState,
      showErrMsg: error ? true : false,
    }));

    setValidationErrMsg(error);
    return error ? true : false;

  }

  const checkEmailValue = () => {
    const hasError = handleEmailValidation(personalInfoFields.email);

    if (hasError) return;
  }

  const handleBvnInputsChange = (event) => {
    const { maxLength, value } = event.target;
    const bvnValue = value.replace(/[^0-9 ]/g, "").slice(0, maxLength);

    setBvnInputs((prevState) => ({
      ...prevState,
      bvn_no: bvnValue,
    }));
  }

  const trackedBVNEntered = () => {
    /**-----------------SEGMENT---------------------------- */
    eventLogger.pushEventGlobally(PREQUALIFICATION_BVN_ENTERED);
    /**---------------------------------------------------------------- */
  }
  const handleSubmit = async (token) => {

    const { bvn_no } = bvnInputs;

    const payload = {
      "bvn": bvn_no,
      "dateOfBirth": moment(personalInfoFields.dateOfBirth).format('YYYY-MM-DD'),
      "firstName": personalInfoFields.firstName,
      "middleName": personalInfoFields.middleName,
      "lastName": personalInfoFields.lastName,
      "email": personalInfoFields.email,
      "captchaToken": token

    };

    const WebAppURL = `${process.env.WAITLIST_API}/api/bvn/validate`
    try {
      const response = await fetch(WebAppURL, {
        headers: {
          Token: `${process.env.WAITLIST_TOKEN}`,
          "Content-Type": 'application/json'
        },
        method: "POST",
        body: JSON.stringify(payload),
      })

      const data = await response.json();

      if (data.isSuccessful) {
        dispatch({
          key: 'verifyBVNForm',
          value: false,
          type: "CHANGE_VIEW_MODALS",
        })        
        props.handleSeoTitle("Kuda | Overdraft Eligibility | You might be eligible ")
        handleNextClick();

      } else {
        dispatch({
          key: 'verifyBVNForm',
          value: false,
          type: "CHANGE_VIEW_MODALS",
        })

        dispatch({
          key: 'bvnFailedForm',
          value: true,
          type: "CHANGE_VIEW_MODALS",
          message: data?.message || "We couldn’t verify your BVN. Please try again."
        })

        /**-----------------SEGMENT---------------------------- */
        const segmentData = {
          [REASON]: data?.message || "We couldn’t verify your BVN. Please try again."
        }
        eventLogger.pushEventGlobally(PREQUALIFICATION_BVN_VERIFICATION_FAILED, segmentData);
        /**---------------------------------------------------------------- */
        props.handleSeoTitle("Kuda | Overdraft Eligibility | We need more information")
      }
    } catch (error) {
      dispatch({
        key: 'verifyBVNForm',
        value: false,
        type: "CHANGE_VIEW_MODALS",
      })

      dispatch({
        key: 'bvnFailedForm',
        value: true,
        type: "CHANGE_VIEW_MODALS",
        message: error.message
      })
      return error
    }

  };

  return (
    <OverdraftWrap id="overdraft">

      {(() => {
        switch (activePage) {
          case 0:
            return (
              <AgreementPolicy setTermsAgreed={setTermsAgreed}
                termsAgreed={termsAgreed}
                handleNextClick={handleNextClick}
              />
            )

          case 1:
            return (

              <PersonalInformation activepage={activePage} handleGoBack={handleGoBack}>
                <PersonalInfoForm activepage={activePage}
                  handleGoBack={handleGoBack}
                  personalInfoFields={personalInfoFields}
                  handleNextClick={handleNextClick}
                  handleDateChange={handleDateChange}
                  handleUserInput={handleUserInput}
                  validationErrMsg={validationErrMsg}
                  checkEmailValue={checkEmailValue}
                />
              </PersonalInformation>

            )

          case 2:
            return (
              <BVNVerification activepage={activePage}
                handleGoBack={handleGoBack} bvnInputs={bvnInputs}
                handleBvnInputChange={handleBvnInputsChange}
                handleSubmit={handleSubmit}
                trackedBVNEntered={trackedBVNEntered}
              />
            )


          case 3:
            return (
              <Summary handleNextClick={handleNextClick}>
                <SummaryDetails />
              </Summary>
            )

          case 4:
            return (
              <EligibileStatus status={status} />
            )

          default:
            return null;

        }
      })()}
    </OverdraftWrap>
  )
}

export default OverDraftCalculator



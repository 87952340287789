import React, { useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import License from "../general/licenses";
import CongratulationIcon from '../../../../assets/img/congrats.inline.svg';
import NoticeIcon from "./../../../../assets/img/noticeIcon.inline.svg";
import EntryBadgesV2 from "../general/badgesV2";

function EligibileStatus(props) {

    const [appsOneLink, setAppsOneLink] = useState('');
    useEffect(() => {

        var oneLinkURL = "https://kuda.onelink.me/abUI"; 
        var mediaSource = { keys: ["utm_source"], defaultValue: "direct_web" };
        var campaign = { keys: ["utm_campaign"], defaultValue: "non_credit_lookalike" };
        var lookalike_audience = { paramKey: "lookalike_audience", keys: ["lookalike_audience"] };
        var custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

        var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL: oneLinkURL,
            afParameters: {
                mediaSource: mediaSource,
                campaign: campaign,
                afCustom: [
                    lookalike_audience,
                    custom_ss_ui
                ]
            }
        });

        var result_url = "No output from script";
        if (result) {
            result_url = result.clickURL;
            setAppsOneLink(result_url);

            if (document.getElementsByTagName('canvas').length === 0) {
                window.AF_SMART_SCRIPT.displayQrCode("my_qr_code_div_id");
            }

        }


    }, [])


    return (
        <EligibileStatusStyle>
            <div className="eligibile--wrap animated kudaFadeInUp">

                <h4 className="color-primary text-center text-xlbold mb-4 kudaFadeInUp animated">

                    {props.status === 'Congrat' ? 'Congratulations!' : 'We Couldn’t Confirm Your Eligibility'}
                </h4>
                <div className="kuda-modal-illustration">

                    {props.status === 'Congrat' ? <CongratulationIcon /> : <NoticeIcon />}
                </div>


                <div className="eligibile--wrap color-black">

                    <NormalText>
                        {props.status === 'Congrat' ?
                            `You might be eligible to take a Kuda Overdraft.
                        Scan the QR code with your phone camera to download the Kuda app now,
                        open an account, and apply.` : `Please, scan the QR code with your phone camera to download the Kuda app and apply for Overdraft all the same.`}
                    </NormalText>
                </div>

                <div className="flex align-center justify-center my-3 web" id='my_qr_code_div_id'>
                </div>

                <div className="eligibile--wrap--btn mb-3 mobile">
                    <EntryBadgesV2 className="pt-3 title-bottom--spacing justify-center" isAmbassador={true} ambassadorUrl={appsOneLink} />
                </div>

                <div className="eligibile--wrap--btn mb-3">
                    <License className='justify-center' />
                </div>


                <BottomText>This is just an eligibility check, not a guarantee that we'll give you a Overdraft.
                    Please, go through the Overdraft application process on the Kuda app to confirm that you can borrow.</BottomText>
            </div>

        </EligibileStatusStyle>
    );
}

export default EligibileStatus;


const EligibileStatusStyle = styled.div`
    max-width: 717px;
    min-height: 665px;
    margin:0px auto;
    border-radius: 5px;
    border: 1px solid rgba(219, 220, 224, 0.50);
    background: var(--White, #FFF);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    #my_qr_code_div_id canvas { 
     height: 105px;
     width: 105px;
    }

    @media only screen and (max-width: 425px){
        border-radius: 0px;
        border: 0px;
        box-shadow: none;
    }

    .eligibile--wrap, .eligibile--wrap--btn {
        max-width: 406px;
        margin: 20px auto;
    }
    .eligibile--wrap .web{
        display: flex !important;
    }
    .eligibile--wrap .mobile{
        display: none !important;
    }
    .sub-info {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        color: var(--textColor);
        max-width: 374px;
        margin: auto;
    }

    @media only screen and (max-width: 425px){
        .eligibile--wrap .web{
            display: none !important;
        }
        .eligibile--wrap .mobile{
            display: block !important;
        }
    }
`;
const NormalText = styled.p`
    color: var(--darkAlt);
    text-align: center;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const BottomText = styled.p`
    max-width: 372px;
    margin: auto;
    color: var(--placeHolder);
    text-align: center;
    font-family: Mulish;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

`;
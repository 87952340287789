import React, { useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import UncheckedIcon from '../../../../../../assets/img/uncheckedIcon.inline.svg';
import CheckedIcon from '../../../../../../assets/img/checkedIcon.inline.svg';
import Button from '../../../general/Button'
import NoticeIcon from "../../../../../../assets/img/noticeIcon.inline.svg";
import styled from "styled-components";

const AgreementPolicy = ({
    handleNextClick,
    termsAgreed,
    setTermsAgreed,
}) => {
    const wrapperRef = useRef();


    return (
        <FormLayoutWrapper>
            <AgreementPolicyWrapper ref={wrapperRef} className="animated kudaFadeInUp">

                <div className="agreement-policy__content">
                    <SubTitleText>Before you complete this Overdraft eligibility.</SubTitleText>

                    <div className="justify-center flex  icon mb-3">
                        <NoticeIcon />
                    </div>

                    <ScrollBarStyle>
                        <PerfectScrollbar className="kuda-terms--wrap animated kudaFadeInUp mt-0" options={{ suppressScrollX: true, maxScrollbarLength: 150 }}>
                            <div className="limit">
                                <div className="mt-3">
                                    <h6 className="sub-text">
                                        To confirm this, we require some preliminary personal information from you.
                                        These are (i) Full Name, (ii) Date of Birth; (iii) Bank Verification Number (BVN)
                                        and (iv) email address. This will enable us to quickly determine if you would be eligible for a Kuda MFB Overdraft,
                                        should you become a Kuda MFB customer by opening an account with us.
                                        We will also share marketing and related information on how to open your Kuda MFB Account.
                                    </h6>
                                </div>
                                <div className="mt-3">
                                    <h6 className="sub-text">
                                        We confirm that we will only collect, store and process the personal data you are sharing
                                        with  us  for  the  purposes  we  have indicated  above  subject  to  applicable laws.
                                        Our <a href="https://kuda.com/en-ng/legal/privacy-policy/" target="_blank" rel="noopener noreferrer" className="color-secondary text-bold link-decoration cursor-pointer">Privacy Policy</a> contains information about how we collect, store and process your personal data.
                                    </h6>
                                </div>
                                <div className="mt-3">
                                    <h6 className="sub-text">
                                        By clicking “I Agree” below, you hereby authorise Kuda MFB to collect, use, save, disclose
                                        and / or process the personal data you are providing to us for the purposes indicated above.
                                        If you do not wish to provide your personal data and the required consent, please do not proceed.
                                    </h6>
                                </div>


                            </div>
                        </PerfectScrollbar>
                    </ScrollBarStyle>
                </div>

                <div className="agreement-policy__buttons-wrap">
                    <div
                        className="agreement-policy__buttons-left"
                        onClick={() => setTermsAgreed(!termsAgreed)}
                    >
                        <div>{termsAgreed ? <CheckedIcon /> : <UncheckedIcon />}</div>
                        <NormalText>I agree and wish to continue</NormalText>
                    </div>

                    <div className="mx-auto btn-continue">
                        <Button
                            width="100%"
                            text="Continue"
                            disabled={!termsAgreed}
                            onButtonClick={handleNextClick}
                        />
                    </div>

                </div>
            </AgreementPolicyWrapper>
        </FormLayoutWrapper>
    );
};
const ScrollBarStyle = styled.div`
	width: 100%;

    .limit{
        height: inherit;
        overflow: auto;

        .sub-text {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #000000;
        }
        
        &::-webkit-scrollbar {
            width: 5px;    
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(64, 25, 109, 0.25); 
            border-radius: 5px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background:  #40196D; 
        }
    }
	
`;
const FormLayoutWrapper = styled.div`
width: 717px;
height: auto;
margin:0px auto;
padding-top: 1.875rem;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
display: flex;
flex-direction: column;
align-items: center;
position: relative;

@media only screen and (max-width: 991px){
 width: 630px;
}
@media only screen and (max-width: 767px){
  width: 100%;
  border-radius: 0px;
  border: 0px;
  box-shadow: none;
}

.kuda-page--title {
    font-size: 1.75rem;
    font-style: normal;
    font-family: 'Mulish';
    font-weight: 800;
    line-height: 34px; 
    margin-top: 34px;
}

`

const AgreementPolicyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid rgba(219, 220, 224, 0.5);
    background: #fff;
    box-shadow: 15px 30px 40px 0px rgba(0, 0, 0, 0.07);
    width: 496px;
    max-width: 100%;
    margin-bottom: 2.563rem;

    .agreement-policy__top-wrap {
        padding: 16px 16px 0px 0px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    .agreement-policy__content {
        margin-top: -8px;
        padding: 40px 40px 0px 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .agreement-policy__content-text {
            margin-bottom: 20px;
        }
    }
    .kuda-terms--wrap {
        max-width: 406px;
        max-height: 670px;
        margin-left: auto;
        margin-right: auto;
    }

   

    .agreement-policy__buttons-wrap {
        padding: 24px;
        border-top: 1px solid rgba(219, 220, 224, 0.5);
        width: 100%;

        .agreement-policy__buttons-left {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            margin-bottom: 25px;
            border-radius: 4px; 
            background: #F9F9F9;
            height: 50px;
            padding: 0px 16px;
        }
    
        .btn-continue{
            width: 281px;
        }
    }


    @media only screen and(max-width: 425px) {

        .agreement-policy__content {
            padding: 42px 20px 0px 20px;
        }
       
        .kuda-terms--wrap {
            height: calc(100vh - 550px) !important;
        }

        .agreement-policy__buttons-wrap .btn-continue{
            width: auto;
        }
    }

`;
const SubTitleText = styled.h3`
    font-family: 'Mulish';
    color: #000;
    font-size: 28px;
    font-weight: 800;
    line-height: 34px;
    margin-bottom: 32px;
    text-align: center;
    max-width: 344px;
    color: var(--primaryColor);
 
    @media only screen and (max-width: 425px){
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 12px;
        max-width: 242px;
    }
`;

const NormalText = styled.p`
    color: #000;
    font-size: 15px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export default AgreementPolicy;

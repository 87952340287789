import React from "react"
import Button from '../general/Button'
import FormDatePicker from "../general/FormDatePicker/FormDatePicker";
import styled from "styled-components";
import BackArrow from "../../../../assets/img/back-arrow.inline.svg";

const CalculatorFormWrap = styled.div`
width: 417px;
padding: 1.5rem 1.8rem 2rem 1.8rem; 
margin-top: 25px;
margin-bottom: 53px;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);


.card-group {
    width: 100%;
    position: relative;

    .card-label{
    font-family: 'Mulish';
    }

    .amount-input{
      font-family: 'Mulish';
    }
    .amount-input::placeholder {
      font-family: 'Mulish';
    }
}

@media only screen and (max-width: 425px){
 width: 100%;
}
`

const ageRestriction = new Date().setFullYear(new Date().getFullYear() - 18);

const PersonalInfoForm = ({ personalInfoFields, handleNextClick, handleDateChange, handleUserInput, ...props }) => {


  return (
    <CalculatorFormWrap>
      {props.activepage > 0 && (
        <button
          onClick={props.handleGoBack}
          type="button"
          className="back-to color-primary"
        >
          <span className="backArrow">
            <BackArrow />
          </span>
          Back
        </button>
      )}


      <div className="card-group">
        <label className="card-label color-black">Legal First Name </label>
        <div className="add-amount">
          <input
            className="amount-input normal-input"
            placeholder="Ciroma"
            type="text"
            name="firstName"
            value={personalInfoFields?.firstName}
            onChange={handleUserInput}
            maxLength={50}
            minLength={1}
          />
        </div>
      </div>

      <div className="card-group mr-3 mt-25">
        <label className="card-label color-black">Legal Middle Name (Optional) </label>
        <div className="add-amount">
          <input
            className="amount-input normal-input"
            placeholder="Nkechi"
            type="text"
            name="middleName"
            value={personalInfoFields?.middleName}
            onChange={handleUserInput}
            maxLength={50}
            minLength={1}
          />
        </div>
      </div>

      <div className="card-group">
        <label className="card-label color-black">Legal Last Name </label>
        <div className="add-amount">
          <input
            className="amount-input normal-input"
            placeholder="Adeleke"
            type="text"
            name="lastName"
            value={personalInfoFields?.lastName}
            onChange={handleUserInput}
            maxLength={50}
            minLength={1}
          />
        </div>

      </div>

      <div className="card-group">
        <label className="card-label color-black">Email Address </label>
        <div className="add-amount">
          <input
            className="amount-input normal-input"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            value={personalInfoFields?.email}
            onChange={handleUserInput}
            minLength={10}
            onBlur={props.checkEmailValue}
          />
        </div>

        {personalInfoFields?.showErrMsg && (
          <p className="color-negative text-sm text-semi-bold mt-2">
            {props.validationErrMsg}
          </p>
        )}
      </div>


      <div className="card-group">
        <label className="card-label color-black">Date of Birth</label>

        <div className="add-amount">
          <FormDatePicker
            onchange={handleDateChange}
            value={personalInfoFields?.dateOfBirth || ""}
            maxDate={ageRestriction}
          />
        </div>

      </div>

      <div className="">
        <Button
          text="Next"
          width='100%'
          disabled={
            personalInfoFields?.firstName === "" ||
            personalInfoFields?.lastName === "" ||
            personalInfoFields?.dateOfBirth === null ||
            personalInfoFields?.dateOfBirth === "" ||
            personalInfoFields?.email === "" ||
            props.validationErrMsg !== ""
          }
          onButtonClick={handleNextClick}
        />
      </div>
    </CalculatorFormWrap>
  )
}

export default PersonalInfoForm

import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import OverDraftCalculator from "../../components/body/pages/overdraft-eligibility-form"

const OverdraftsPage = () => {
  const [seoTitle, setSeoTitle] = useState('')
  return (
    <Layout>
      <SEO
        title={seoTitle ?? ''}
        noIndex={true}
      />
      <OverDraftCalculator handleSeoTitle={(title) => setSeoTitle(title)} />
    </Layout>
  )
}

export default OverdraftsPage

import React from "react"
import BackArrow from "../../../../assets/img/back-arrow.inline.svg";
import styled from "styled-components";

const FormLayoutWrapper = styled.div`
width: 717px;
height: auto;
margin:0px auto;
padding-top: 1.875rem;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
display: flex;
flex-direction: column;
align-items: center;
position: relative;
font-family: 'Mulish';

.btn--web{
  display: block;
}
.kuda-page--title {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    font-family: 'Mulish';
    line-height: 34px; 
    margin-top: 34px;
    text-align: center;
}
.sub-description{
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Mulish';
  line-height: 20px;
  margin-top: 21px;
  max-width: 331px;
  color: var(--blackColor);
}

.text-eligible{
  width: 317px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-family: 'Mulish';
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 22px;
}
.agree-policy-container{
  width: 335px;
  margin-bottom: 19px;

  .policy-text-grey{
    color: #979797;
    font-family: 'Mulish';
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  }

  .policy-text-green{
    color: var(--successColor);
    font-family: 'Mulish';
    font-size: 13px;
    font-weight: 900;
    line-height: 15px;
    margin-left: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px){
  width: 630px;
 }
 @media only screen and (max-width: 767px){
   width: 100%;

   .back-to {
    left: 8px;
    top: 15px;
    } 
 }
 
 @media only screen and (max-width: 576px){
     width: 100%;
     border-radius: 0px;
     border: 0px;
     box-shadow: none;

     .btn--web{
      display: none;
     }

     .back-to {
      left: 0px !important;
      top: 0px !important;
      position: relative;
      margin-bottom: 2rem;
      padding: 0px;
    }
 }
 @media only screen and (max-width: 425px){
  .kuda-page--title{
    font-size: 20px;
    margin-top: 0px;
    font-weight: 900;
    line-height: 25px;
  }

}
`

const PersonalInformation = (props) => {


  return (
      <FormLayoutWrapper>
        {props.activepage > 0 && (
          <button
            onClick={props.handleGoBack}
            type="button"
            className="back-to color-primary btn--web"
          >
            <span className="backArrow">
              <BackArrow />
            </span>
            Back
          </button>
        )}


        <h2 className="kuda-page--title color-primary text-center">Personal Information</h2>
        <span className="sub-description">A few details about you.</span>

        {props.children}
      </FormLayoutWrapper>

  )
}

export default PersonalInformation



import styled from "styled-components"

export const OverdraftWrap = styled.section`
width: calc(100% - 0px);

.back-to{
  background: none;
  font-family: 'Mulish';
}

@media only screen and (max-width: 991px){
  padding-top: 40px;
}
@media only screen and (max-width: 767px){
  padding-top: 0px;

  .back-to {
    left: 8px;
    top: 15px;
  }
}
`
export const FormLayoutWrapper = styled.div`
width: 717px;
height: auto;
margin:0px auto;
padding-top: 1.875rem;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
display: flex;
flex-direction: column;
align-items: center;
position: relative;

@media only screen and (max-width: 991px){
 width: 630px;
}
@media only screen and (max-width: 767px){
  width: 100%;
}

@media only screen and (max-width: 576px){
  width: 100%;
  border-radius: 0px;
  border: 0px;
  box-shadow: none;
  padding: 0px;
}

.kuda-page--title {
    margin-top: 34px;
    text-align: center;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
}
.sub-description{
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Mulish';
  line-height: 20px;
  margin-top: 21px;
  max-width: 331px;
  color: var(--blackColor);
}


.text-eligible{
  width: 317px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-family: 'Mulish';
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 22px;
}
.agree-policy-container{
  width: 335px;
  margin-bottom: 19px;

  .policy-text-grey{
    color: #979797;
    text-align: center;
    font-family: 'Mulish';
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  }

  .policy-text-green{
    color: var(--successColor);
    font-size: 13px;
    font-weight: 900;
    font-family: 'Mulish';
    line-height: 15px;
    margin-left: 5px;
    cursor: pointer;
  }
}

`

export const CalculatorFormWrap = styled.div`
width: 417px;
padding: 1.5rem 1.8rem 2rem 1.8rem; 
margin-top: 25px;
margin-bottom: 53px;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);


.card-group {
    width: 100%;
    position: relative;
}
`
export const SummaryDetailsWrap = styled.div`
width: 335px;
padding: 27px 22px;
margin-top: 25px;
margin-bottom: 40px;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);

.summary-details-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.1rem;
}

.summary-details-text{
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  line-height: 20px;
  color: var(--blackColor);
  text-align: center;
  max-width: 285px;
}
`

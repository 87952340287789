import React from "react";
import DatePicker from "react-datepicker";
import CalenderIcon from '../../../../../assets/img/calendar-icon.inline.svg';
import styled from "styled-components";
import './FormDatePicker.css';

const DatePickerWrapper = styled.div`
    width: 100%;

    .form-datepicker {
        width: 100%;
        height: 46px;
        border: 1px solid #f4f4f4;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background: #ffffff;
        position: relative;
    }
    .formInput{
        font-family: 'Mulish';

        &::placeholder{
            font-family: 'Mulish';
            font-weight: 600;
            color: var(--placeHolder) !important;
            line-height: 16px;
            letter-spacing: 0.01rem;
        }
    }
    .bus_reg_dataInput {
        padding-left: 40px;
    }
    .bus_reg-date-icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
    }
    p {
        display: block;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        font-size: 13px;
    }

    /* DATE PICKER */

.react-datepicker__day,
.react-datepicker__time-name {
    color: #444444 !important;
}

.react-datepicker-popper {
    width: 100% !important;
}

.react-datepicker,
.react-datepicker__month-container {
    width: calc(100% + 4px) !important;
    position: relative;
    left: -2px;
}

.react-datepicker {
    font-family: "Muli", sans-serif !important;
    font-size: 0.9rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    line-height: 2.5rem !important;
}

.react-datepicker__header {
    background: none !important;
    border-bottom: 0 !important;
}

.react-datepicker__current-month {
    margin-bottom: 7px !important;
}

.react-datepicker {
    border: 1px solid var(--bgOffset) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--bgOffset) !important;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__header {
    padding-top: 20px !important;
}

.react-datepicker__day-name {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: var(--blackColor) !important;
    margin-top: 15px !important;
    line-height: 1rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day:hover,
.react-datepicker__time-name {
    border-radius: 100px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background: none !important;
}

.react-datepicker__day.react-datepicker__day--selected{
    color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 100px !important;
    background: none !important;
}

.react-datepicker__navigation {
    outline: none !important;
}

.react-datepicker__day {
    position: relative !important;
    z-index: 2 !important;
}

.react-datepicker__day:before {
    background: var(--bgOffset) !important;
    content: "" !important;
    position: absolute !important;
    width: 34px !important;
    height: 34px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 100px !important;
    z-index: -1 !important;
    opacity: 0 !important;
}

.react-datepicker__day:hover:before {
    opacity: 1 !important;
}

react-datepicker__day--selected{
    color: #fff !important;
}
.react-datepicker__day.react-datepicker__day--selected:before {
    opacity: 1 !important;
}

.react-datepicker__day.react-datepicker__day--selected:before {
    background: var(--primaryColor) !important;
}

.react-datepicker__navigation {
    top: 20px !important;
}

.react-datepicker__day--outside-month {
    opacity: 0.09 !important;
    pointer-events: none !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 12% !important;
}

    @media screen and (max-width: 710px) {
        .form-datepicker {
            width: 100%;
        }
    }
    @media screen and (max-width: 375px) {
        .react-datepicker-popper {
            width: 90% !important;
        }
    }
`;

export default function FormDatePicker({ onchange, value, maxDate }) {


    const handleStartChange = (date) => {
        onchange(date)
    };

    return (
        <DatePickerWrapper>
            <div className="form-datepicker">
                <div className="bus_reg-date-icon">
                    <CalenderIcon />
                </div>
                <DatePicker
                    className="formInput kuda-cta-wide bus_reg_dataInput"
                    placeholderText="Select Date"
                    selected={value}
                    onChange={handleStartChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                    maxDate={maxDate ? maxDate : new Date()}
                />
            </div>
        </DatePickerWrapper>
    );
}



import React from "react"
import { SummaryDetailsWrap } from '../../style';
import CashLoanIcon from '../../../../../../assets/img/cash-loan.inline.svg';


const SummaryDetails = () => {


    return (
        <SummaryDetailsWrap>
            <div className="summary-details-logo">
                <CashLoanIcon />
            </div>

            <p className="summary-details-text mt-5">
                You can get an overdraft of up to <span className="text-bold">₦50,000</span> at a daily interest rate of <span className="text-bold">0.5%.</span> You'll have to pay back the overdraft and interest in at most <span className="text-bold">90 days.</span>
            </p>
        </SummaryDetailsWrap>
    )
}

export default SummaryDetails
